import React from "react"
import { Container, Box, Heading } from "@chakra-ui/react"

import { useCore } from "@app/hooks/useCore"
import { withSection } from "@app/hoc/Section"
import { useRoutes } from "@app/hooks/useRoutes"
import Link from "@app/components/Link"

type Props = {
  link: any
  theme: any
  content: any
  title: string
  alignment: any
  bgColor: any
  innerRef: any
  inView: boolean
  handleTrackingClick: () => void
}

const Text: React.FC<Props> = ({
  content: rawContent,
  link: rawLink,
  title,
  theme,
  alignment,
  bgColor,
  innerRef,
  handleTrackingClick,
  tag,
}) => {
  const {
    helpers: { sanityContent },
  } = useCore()
  const { urlResolver } = useRoutes()
  const content = sanityContent(rawContent)
  const link = urlResolver(rawLink)

  return (
    <Container
      ref={innerRef}
      as="section"
      maxW="11xl"
      bgColor={bgColor?.hex}
      px={[4, 8]}
      py={[8, 8]}
      bg={theme === "Dark" ? "brand.light" : "white"}
      textAlign={alignment?.toString() || "center"}
    >
      <Heading as={tag} size="xl" mb={4}>
        {title}
      </Heading>
      {content && (
        <Box mt={2} textAlign={alignment?.toLowerCase()}>
          {content}
        </Box>
      )}
      {link?.url && (
        <Link title={link?.title} to={link?.url} onClick={handleTrackingClick}>
          {link?.title}
        </Link>
      )}
    </Container>
  )
}

export default React.memo(withSection(Text))
